<template>
  <div class="container page">
    <van-nav-bar :title="$t('withdraw.title')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="$router.push({path:'/WithdrawRecord'})">{{ $t("withdraw.record") }}</span>
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="withdrawMoney">
        <span>{{ $t('withdraw.money') }}</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType"> </span>
            <van-field v-model="withdraw_money" type="number" />
          </div>
          <span class="all" @click="allMoeny()">{{ $t('withdraw.all') }}</span>
        </div>
        <div class="information">
          <div class="description">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px;">
                <p>1.{{ $t('withdraw.min') }}{{this.withdrawRole.min}},{{ $t('withdraw.max') }}{{this.withdrawRole.max}}</p>
                <p>2.{{ $t('withdraw.num') }}{{this.withdrawRole.num}}{{ $t('msg.次') }}</p>
                <p>3.{{ $t('withdraw.hint') }}</p></div>
              <template #reference @click="withdrawInfo()">
                <van-icon name="info-o" />
                {{ $t('withdraw.quota') }}
              </template>
            </van-popover>
          </div>

          <div class="balance">
            <span>{{ $t('withdraw.balance') }}：</span>
            <span class="number">{{ this.userInfo.money }}</span>
          </div>
        </div>
        <!-- 新增提现密码输入字段 -->
        <div class="withdrawPassword">
          <span>{{ $t('setPayPassword.opw') }}</span>
          <van-field v-model="withdraw_password" type="password" placeholder="" />
        </div>
      </div>
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()">{{ $t("withdraw.withdraw") }}</van-button>
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js';
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money:"",
      withdraw_password: "",  // 新增字段
      userInfo:{},
      withdrawRole:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserWithdrawRole(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res=>{
        if(res.code === 200){
          this.withdrawRole = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    allMoeny(){
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw() {
      if (this.withdraw_money <= 0) {
        this.$toast(this.$t("msg.请填写正确的金额"));
        return false;
      }
      
      // 本地验证提现密码
      if (CryptoJS.MD5(this.withdraw_password).toString() !== this.userInfo.paypassword) {
        this.$toast(this.$t("msg.密码错误！"));
        return false;
      }
      
      this.$http({
        method: 'get',
        data: { money: this.withdraw_money },
        url: 'user_set_withdraw'
      }).then(res => {
        if (res.code === 200) {
          location.reload();
          this.getUserInfo();
          this.getUserWithdrawRole();
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
        let num = "";
        if (res.msg == "提现金额不能少于：") {
          num = this.withdrawRole.min;
        } else if (res.msg == "提现金额不能大于：") {
          num = this.withdrawRole.max;
        }
        this.$toast(this.$t("msg." + res.msg) + num);
      });
    },
    withdrawInfo(){
      this.showPopover = true;
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserWithdrawRole();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.container .main{
  display: flex;
  flex-direction: column;
  background-color: #f2f2f5;
  height: calc(100% - 50px);
  position: relative;
}
.container .main .withdrawMoney{
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 35px;
  background-color: #fff;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f5;
}
.container .main .withdrawMoney .money .moneyNumber{
  font-size: 50px;
  display: flex;
  flex-direction: row;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money .all{
  color: #d10404;
}
.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0!important;
}
.container .main .withdrawMoney .information{
  padding-bottom: 30px;
}
.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}
.container .main .withdrawMoney span{
  padding: 10px 0;
}
.container .main .withdrawMoney .information .balance .number{
  color: #d10404;
}
.withdraw_btn {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}
/* 新增提现密码输入字段样式 */
.withdrawPassword {
  margin-top: 20px;
}
.withdrawPassword span {
  font-size: 35px;
}
.withdrawPassword .van-field {
  font-size: 30px;
}
</style>
